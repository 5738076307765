<div class="row mt-5">
    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mt-5">
        <div class="card card-body d-flex flex-column align-items-center">
            <img src="../../../../../assets/media/icon/vehicle-entry.svg" alt="" class="w-75">
            <span class="text-center mt-3 fs-3 mt-3">Visitor Entry</span>
        </div>
    </div>
    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mt-5">
        <div class="card card-body d-flex flex-column align-items-center">
            <img src="../../../../../assets/media/icon/visitor-status.svg" alt="" class="w-75">
            <span class="text-center mt-3 fs-3 mt-3">Visitor Status</span>
        </div>
    </div>
    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mt-5">
        <div class="card card-body d-flex flex-column align-items-center">
            <img src="../../../../../assets/media/icon/vehicle-entry.svg" alt="" class="w-75">
            <span class="text-center mt-3 fs-3 mt-3">Vehicle Entry</span>
        </div>
    </div>
    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mt-5">
        <div class="card card-body d-flex flex-column align-items-center">
            <img src="../../../../../assets/media/icon/vehicle-status.svg" alt="" class="w-75">
            <span class="text-center mt-3 fs-3 mt-3">Vehicle Status</span>
        </div>
    </div>
    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mt-5">
        <div class="card card-body d-flex flex-column align-items-center">
            <img src="../../../../../assets/media/icon/report.svg" alt="" class="w-75">
            <span class="text-center mt-3 fs-3 mt-3">Report</span>
        </div>
    </div>
</div>